.department-grid-item:hover {
    opacity: 1 !important;
}

.department-new-modal {
    max-height: 55vh;
    max-width: 90%;
    overflow-y: auto;
    overflow-x: hidden;

    -ms-overflow-style: auto;
    scrollbar-color: #C4C4C4 #E2E2E2;
    scrollbar-width: thin;
}

.department-new-modal::-webkit-scrollbar-thumb {
    background-color: #C4C4C4;
}

.department-new-modal::-webkit-scrollbar-track {
    background-color: #E2E2E2;
}

.department-new-modal::-webkit-scrollbar-corner {
    background-color: #E2E2E2;
}

.department-new-modal::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
}

.department-new-modal {
    -ms-overflow-style: auto;
    scrollbar-color: #C4C4C4 #E2E2E2;
    scrollbar-width: thin;
}