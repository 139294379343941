.background-square-1 {
    position: absolute;
    width: 75px;
    height: 74px;
    left: 163px;
    top: 40%;

    background: #003B65;
}

.background-square-2 {
    position: absolute;
    width: 75px;
    height: 74px;
    left: 163px;
    top: 55%;

    background: #003B65;
}

.text-input {
    border: solid 2px transparent !important;
    border-radius: 6.91;

    padding: 5px !important;

}

.text-input:hover {
    /* border: solid 2px #003459 !important; */
    opacity: 1 !important;
}